import { Typography, Link } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';

function Footer() {
	const theme = useTheme();
	const styles = {
		footer: {
			backgroundColor: 'black',
			color: 'white',
			padding: theme.spacing(2),
		},
		link: {
			color: 'inherit',
			'&:hover': {
				color: theme.palette.secondary.main,
			},
		},
	};
	const currentYear = new Date().getFullYear();

	return (
		<footer style={styles.footer}>
			<Typography variant='h6' align='center'>
				<Link style={styles.link} href='/about-us'>
					Om
				</Link>
				{' | '}
				<Link style={styles.link} href='/contact-us'>
					Kontakt
				</Link>
				{' | '}
				<Link style={styles.link} href='/privacy-policy'>
					Persondata
				</Link>
				{' | '}
				<Link style={styles.link} href='/terms-of-use'>
					Vilkår og betingelser
				</Link>
				{' | '}
				<Link style={styles.link} href='/help'>
					Hjælp
				</Link>
				{' | '}
				<Link style={styles.link} href='/faq'>
					Ofte stillede spørgsmål
				</Link>
				{' | '}
				<Link style={styles.link} href='/blog'>
					Blog
				</Link>
				{' | '}
				<Link style={styles.link} href='/newsletter'>
					Nyhedsbrev
				</Link>
			</Typography>
			<Typography variant='body2' align='center'>
				{'Ophavsrettigheder © '}
				<Link style={styles.link} href='/'>
					nemmadplan.dk{' '}
				</Link>
				{currentYear}
				{'.'}
			</Typography>
			<Typography variant='body2' align='center'>
				{'Følg os på: '}
				<Link style={styles.link} href='/facebook'>
					<FacebookIcon fontSize='inherit' />
				</Link>
				{' | '}
				<Link style={styles.link} href='/twitter'>
					<TwitterIcon fontSize='inherit' />
				</Link>
				{' | '}
				<Link style={styles.link} href='/instagram'>
					<InstagramIcon fontSize='inherit' />
				</Link>
			</Typography>
		</footer>
	);
}

export default Footer;
