/** @format */

import React, { useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import CustomizedHook from './SearchBar/CustomHookSearchBar';
// import { getGPTResponse } from './GPT-3/GetGPTResponse';
import { Configuration, OpenAIApi } from 'openai';
import Recipe from '../App/Recipe/Recipe';
import { auth } from '../../firebase/config';
import { useAuthState } from 'react-firebase-hooks/auth';
import Categories from './Categories';

const configuration = new Configuration({
	apiKey: process.env.REACT_APP_OPENAI_API_KEY,
});

const openai = new OpenAIApi(configuration, 'https://api.openai.com/v1/chat');

const NemMadplan = () => {
	const [searchTerm, setSearchTerm] = React.useState('');
	const [selectedOption, setSelectedOption] = React.useState('morgenmad');
	const [recipe, setRecipe] = React.useState('');
	const [user, loading, error] = useAuthState(auth);
	const navigate = useNavigate();

	useEffect(() => {
		if (loading) {
			// maybe trigger a loading screen
			return;
		}
		if (user) navigate('/madplan');
		if (!user) navigate('/login');
	}, [user, loading, navigate]);

	const getGPTResponse = async prompt => {
		const ingredienser = prompt
			.map(item => item.name.toLowerCase())
			.join(', ');

		const completion = await openai.createCompletion({
			model: 'gpt-3.5-turbo',
			// prompt: `SKRIV EN LEVENDE OG ENGAGERENDE KETO OPSKRIFT MED FÅ KULHYDRATER OG UDEN KULHYDRATER I JSON FORMAT PÅ DANSK.  DISSE INGREDIENSER SKAL INDGÅ I OPSKRIFTEN: ${ingredienser}. KATEGORIEN SKAL VÆRE ${selectedOption?.toUpperCase()} ("category": "${selectedOption}").\n\nKETO OPSKRIFT MED FÅ KULHYDRATER OG UDEN SUKKER I JSON FORMAT. KATEGORIEN SKAL VÆRE MORGENMAD ("category": "${selectedOption}"):\n\n###\n\n{`,
			// temperature: 0.0,
			// max_tokens: 1875,
			// top_p: 1,
			// frequency_penalty: 0.0,
			// presence_penalty: 0,
			// stop: ['END'],
			messages: [
				{
					role: 'user',
					content: `SKRIV EN LEVENDE OG ENGAGERENDE OPSKRIFT MED FÅ KULHYDRATER OG UDEN KULHYDRATER I JSON FORMAT PÅ DANSK. Undgå trailing kommaer. Opskriften skal være ketovenlig, og mængderne skal være tilpasset, så 4 personer kan blive godt mætte. Vælg udelukkende velkendte klassikere som f.eks.: pasta carbonara, pizza, lasagne, beef wellington, , eggs benedict, flæskesteg, gordon bleu etc., og tilpas, så alle ingredienser indgår og erstat kulhydratrige ingredienser med mere ketovenlige. ALLE DISSE INGREDIENSER SKAL SOM MINIMUM INDGÅ I OPSKRIFTEN: ${ingredienser}, MEN DER MÅ MEGET GERNE TILFØJES FLERE, DOG MAKSIMALT 8 INGREDIENSER I ALT. KATEGORIEN SKAL VÆRE ${selectedOption?.toUpperCase()}. Outputtet skal være en tekst, der kan parses til JSON med funktionen JSON.parse(). Formatet skal være { "opskriftNavn": "Opskriftens titel", "beskrivelse": "Fængende underoverskrift, der opsummerer og vækker appetitten på ca. 2-3 linjer.", "antalPersoner": 4, "næringsindhold": {"kalorier": "300 kcal", "kulhydrater": "40 kcal", "protein": "80 kcal", "fedt": "180 kcal" }, "kategori": "Opskriftens kategori", "ingredienser": [{"navn": "Ingrediens1", "mængde": 2, "enhed": "spsk"}, {"navn": "Ingrediens2", "mængde": 1, "enhed": "håndfuld"}, {"navn": "Ingrediens3", "mængde": 6, "enhed": "stk"},], "instruktioner": [{"nummer": 1, "text": "Instruktion 1"}, {"nummer": 2, "text": "Instruktion 2"},], "blogTitle": "Fængende overskrift", "blogPost": "tekst på minimum 1022 ord om personlige oplevelser og erfaringer med opskriften" }.`,
				},
			],
			temperature: 0.8,
		});

		setRecipe(completion.data.choices[0].message);
	};

	const handleClicked = () => {
		setRecipe('Loading...');
		getGPTResponse(searchTerm);
	};

	return (
		<Box
			sx={{
				marginTop: '50px',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',

				padding: '20px',
				border: '1px solid #e0e0e0',
				borderRadius: '4px',
				boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
			}}>
			<Typography variant='h5' component='h2'>
				Nem madplan
			</Typography>
			<Typography variant='body2' component='p'>
				Planlæg dine måltider og få lavet en indkøbsseddel.
			</Typography>
			<Categories
				setSelectedOption={setSelectedOption}
				selectedOption={selectedOption}
			/>
			<CustomizedHook setSearchTerm={setSearchTerm} />

			<Button
				variant='contained'
				color='primary'
				sx={{ marginTop: '20px' }}
				component={Link}
				onClick={handleClicked}>
				Prøv nu
			</Button>
			<Recipe recipe={recipe} />
		</Box>
	);
};

export default NemMadplan;
