import { useState } from 'react';
import {
	AppBar,
	Toolbar,
	Typography,
	IconButton,
	Menu,
	MenuItem,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

const Header = ({ title, color, position }) => {
	const [menuOpen, setMenuOpen] = useState(false);

	return (
		<AppBar
			color={color}
			position={position}
			sx={{ backgroundColor: 'transparent' }}>
			<Toolbar>
				<IconButton
					aria-label='menu'
					onClick={() => setMenuOpen(!menuOpen)}>
					<MenuIcon sx={{ color: 'whitesmoke' }} />
				</IconButton>
				<Typography variant='h6'>{title}</Typography>
				<Menu
					anchorEl={null}
					open={menuOpen}
					onClose={() => setMenuOpen(false)}>
					<MenuItem onClick={() => setMenuOpen(false)}>
						Menu item 1
					</MenuItem>
					<MenuItem onClick={() => setMenuOpen(false)}>
						Menu item 2
					</MenuItem>
					<MenuItem onClick={() => setMenuOpen(false)}>
						Menu item 3
					</MenuItem>
				</Menu>
			</Toolbar>
		</AppBar>
	);
};

export default Header;
