import React, { useState } from 'react';
import api from '../services/api';

const SignIn = () => {
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [error, setError] = useState('');

	const handleSubmit = async event => {
		event.preventDefault();
		try {
			await api.post('/sign-in', { username, password });
			// Redirect to home page
		} catch (error) {
			setError(error.response.data.message);
		}
	};

	return (
		<main>
			<h1>Sign In</h1>
			{error && <p>{error}</p>}
			<form onSubmit={handleSubmit}>
				<label htmlFor='username'>Username</label>
				<input
					type='text'
					id='username'
					name='username'
					value={username}
					onChange={event => setUsername(event.target.value)}
				/>
				<label htmlFor='password'>Password</label>
				<input
					type='password'
					id='password'
					name='password'
					value={password}
					onChange={event => setPassword(event.target.value)}
				/>
				<button type='submit'>Sign In</button>
			</form>
		</main>
	);
};

export default SignIn;
