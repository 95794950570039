import React, { useState, useEffect } from 'react';
import api from '../services/api';

const UserProfile = () => {
	const [user, setUser] = useState({});

	useEffect(() => {
		const fetchData = async () => {
			const result = await api.get('/user-profile');
			setUser(result.data);
		};
		fetchData();
	}, []);

	return (
		<main>
			<h1>User Profile</h1>
			<p>Username: {user.username}</p>
		</main>
	);
};

export default UserProfile;
