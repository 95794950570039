import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import api from '../services/api';

const MealPlan = () => {
	const [mealPlan, setMealPlan] = useState([]);

	useEffect(() => {
		const fetchData = async () => {
			const result = await api.get('/meal-plan');
			setMealPlan(result.data);
		};
		fetchData();
	}, []);

	return (
		<main>
			<h1>Meal Plan</h1>
			<ul>
				{mealPlan.map(day => (
					<li key={day.id}>
						<Link to={`/recipe/${day.recipeId}`}>{day.name}</Link>
					</li>
				))}
			</ul>
			<Link to='/add-recipe'>Add recipe to meal plan</Link>
		</main>
	);
};

export default MealPlan;
