import { Button } from '@mui/material';
import HeroSlider, { Overlay, Slide, MenuNav } from 'hero-slider';
import Wrapper from './Wrapper';
import Title from './Title';
import Subtitle from './Subtitle';

import Image1 from '../../../../assets/images/Hero/image1.jpg';
import Image2 from '../../../../assets/images/Hero/image2.jpg';
import Image3 from '../../../../assets/images/Hero/image3.jpg';
import Image4 from '../../../../assets/images/Hero/image4.jpg';
import { Link } from 'react-router-dom';

const bogliasco = Image1;
const countyClare = Image2;
const craterRock = Image3;
const giauPass = Image4;

export default function BasicSlider() {
	return (
		<HeroSlider
			height={'100vh'}
			autoplay
			controller={{
				initialSlide: 1,
				slidingDuration: 500,
				slidingDelay: 100,
				onSliding: nextSlide =>
					console.debug('onSliding(nextSlide): ', nextSlide),
				onBeforeSliding: (previousSlide, nextSlide) =>
					console.debug(
						'onBeforeSliding(previousSlide, nextSlide): ',
						previousSlide,
						nextSlide
					),
				onAfterSliding: nextSlide =>
					console.debug('onAfterSliding(nextSlide): ', nextSlide),
			}}>
			<Overlay>
				<Wrapper>
					<Title>nemmadplan.dk</Title>
					<Subtitle>Nemme, sunde og billige madplaner</Subtitle>
					<Title>
						<Button
							variant='contained'
							color='primary'
							sx={{ marginTop: '20px' }}
							component={Link}
							to={'/madplan'}>
							Prøv nu
						</Button>
					</Title>
				</Wrapper>
			</Overlay>

			<Slide
				shouldRenderMask
				label='Snacks'
				background={{
					backgroundImageSrc: giauPass,
					maskBackgroundBlendMode: 'screen',
				}}
			/>

			<Slide
				shouldRenderMask
				label='Nem aftensmad'
				background={{
					backgroundImageSrc: bogliasco,
				}}
			/>

			<Slide
				shouldRenderMask
				label='Salater'
				background={{
					backgroundImageSrc: countyClare,
				}}
			/>

			<Slide
				shouldRenderMask
				label='Familievenlig mad'
				background={{
					backgroundImageSrc: craterRock,
				}}
			/>

			<MenuNav />
		</HeroSlider>
	);
}
