import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Header from './Header';
import Footer from './Footer';
import MealPlan from './MealPlan';
// import RecipeList from './RecipeList';
import Recipe from './App/Recipe/Recipe';
// import RecipeForm from './RecipeForm';
import ShoppingList from './ShoppingList';
// import ShoppingListItem from './ShoppingListItem';
import Settings from './Settings';
import SignIn from './SignIn';
import SignUp from '../components/App/Users/Login/SignUp';
import UserProfile from './UserProfile';
// import NotFound from './NotFound';
import Home from './Home';
import { Box } from '@mui/material';
import NemMadplan from './App/NemMadplan';
import LoginForm from './App/Users/Login/LoginForm';

const App = () => {
	return (
		<Router>
			<Header />
			<Routes>
				{/* <Route path='/recipes' element={<Recipes />} /> */}
				<Route path='/recipe/:recipeId' element={<Recipe />} />
				<Route path='/meal-plan' element={<MealPlan />} />
				<Route path='/login' element={<LoginForm />} />
				<Route path='/shopping-list' element={<ShoppingList />} />
				<Route path='/settings' element={<Settings />} />
				<Route path='/sign-in' element={<SignIn />} />
				<Route path='/register' element={<SignUp />} />
				<Route path='/user-profile' element={<UserProfile />} />
				<Route path='/madplan' element={<NemMadplan />} />
				<Route path='/' element={<Home />} />
			</Routes>

			<Footer />
		</Router>
	);
};

export default App;
