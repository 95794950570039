// card component for benefits with three centered cards with corresponding icons in material ui

import React from 'react';
import {
	Avatar,
	Card,
	CardContent,
	CardHeader,
	Grid,
	Typography,
} from '@mui/material';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import FavoriteIcon from '@mui/icons-material/Favorite';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

const benefits = [
	{
		icon: <RestaurantIcon sx={{ color: 'blueviolet' }} />,
		title: 'Planlæg dine måltider',
		text: 'Giv din uge et kulinarisk løft og planlæg hurtigt og nemt dine måltider.',
	},
	{
		icon: <FavoriteIcon sx={{ color: 'red' }} />,
		title: 'Få sunde opskrifter',
		text: 'Find sunde opskrifter til morgenmad, frokost, aftensmad og snacks.',
	},
	{
		icon: <AttachMoneyIcon sx={{ color: 'gold' }} />,
		title: 'Spar penge',
		text: 'Få inspiration til at spare på dine madindkøb og få mere ud af dine penge.',
	},
];

const Benefits = () => {
	return (
		<Grid margin='40px' container gap='4%' justifyContent='center'>
			{benefits.map(benefit => {
				return (
					<Grid item>
						<Card sx={{ maxWidth: 345 }}>
							<CardHeader
								avatar={<Avatar>{benefit.icon}</Avatar>}
								title={
									<Typography
										gutterBottom
										variant='h5'
										component='div'>
										{benefit.title}
									</Typography>
								}
								justify='flex-start'
							/>
							<CardContent>
								<Typography
									variant='body2'
									color='text.secondary'>
									{benefit.text}
								</Typography>
							</CardContent>
						</Card>
					</Grid>
				);
			})}
		</Grid>
	);
};

export default Benefits;
