import React, { useEffect, useState } from 'react';
// import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, TextField } from '@mui/material';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../../../firebase/config';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Link, useNavigate } from 'react-router-dom';

// const useStyles = makeStyles(theme => ({
// 	root: {
// 		'& > *': {
// 			margin: theme.spacing(1),
// 			width: '25ch',
// 		},
// 	},
// }));

function LoginForm() {
	// const classes = useStyles();
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [user, loading, error] = useAuthState(auth);
	const navigate = useNavigate();

	useEffect(() => {
		if (loading) {
			// maybe trigger a loading screen
			return;
		}
		if (user) navigate('/madplan');
	}, [user, loading, navigate]);

	// const handleSubmit = event => {
	// 	event.preventDefault();
	// 	login(email, password)
	// 		.then(() => {
	// 			// Redirect to home page or show success message
	// 		})
	// 		.catch(error => {
	// 			setError(error.message);
	// 		});
	// };

	return (
		<Box
			sx={{
				marginTop: '50px',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',

				padding: '20px',
				border: '1px solid #e0e0e0',
				borderRadius: '4px',
				boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
			}}>
			{/* <form noValidate autoComplete='off' onSubmit={handleSubmit}>
				<TextField
					id='email'
					label='Email'
					variant='outlined'
					value={email}
					onChange={event => setEmail(event.target.value)}
				/>
				<TextField
					id='password'
					label='Password'
					type='password'
					variant='outlined'
					value={password}
					onChange={event => setPassword(event.target.value)}
				/>
				<Button variant='contained' color='primary' type='submit'>
					Login
				</Button>
				{error && <p>{error}</p>}
			</form> */}

			<div className='login'>
				<div className='login__container'>
					<input
						type='text'
						className='login__textBox'
						value={email}
						onChange={e => setEmail(e.target.value)}
						placeholder='E-mail Address'
					/>
					<input
						type='password'
						className='login__textBox'
						value={password}
						onChange={e => setPassword(e.target.value)}
						placeholder='Password'
					/>
					<button
						className='login__btn'
						onClick={() =>
							signInWithEmailAndPassword(auth, email, password)
						}>
						Login
					</button>

					<div>
						<Link to='/reset'>Forgot Password</Link>
					</div>
					<div>
						Don't have an account?{' '}
						<Link to='/register'>Register</Link> now.
					</div>
				</div>
			</div>
		</Box>
	);
}

export default LoginForm;
