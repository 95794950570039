import React from 'react';
import { Box, Button } from '@mui/material';
import { Link } from 'react-router-dom';

const CTA = () => {
	return (
		<Box margin='40px' sx={{ textAlign: 'center' }}>
			<p>
				Er du klar til at optimere dine madindkøb og spare penge på din
				hverdagsmad? Prøv vores webapp nu og se, hvor nemt det er at
				planlægge dine måltider og få lavet en indkøbsseddel.
			</p>
			<p>
				Med vores brugervenlige interface og ubegrænsede madplaner kan
				du altid have lækre, sunde måltider klar i køleskabet uden at
				bruge unødvendigt mange penge.
			</p>
			<Button
				variant='contained'
				color='primary'
				sx={{ marginTop: '20px' }}
				component={Link}
				to={'/madplan'}>
				Prøv nu
			</Button>
		</Box>
	);
};

export default CTA;
