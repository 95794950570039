/** @format */

import { Container } from '@mui/material';
import React, { useEffect, useState } from 'react';

const Recipe = ({ recipe }) => {
	const [parsedRecipe, setParsedRecipe] = useState({});

	useEffect(() => {
		if (!recipe || recipe === 'Loading...') return;

		console.log('recipe: ', recipe.content);
		try {
			const parsedRecipe = JSON.parse(recipe.content);
			setParsedRecipe(parsedRecipe);
		} catch (error) {
			console.error(error);
		}
	}, [recipe]);
	console.log('parsedRecipe: ', parsedRecipe);
	if (!recipe) return null;

	return (
		<Container
			sx={{
				marginTop: '40px',
				mb: '40px',
				maxWidth: '740px',
			}}>
			{recipe === 'Loading...' ? (
				<h2 style={{ textAlign: 'center' }}>
					🌞 Vi er i gang med at finde opskrifter til dig... 🤞🏼
				</h2>
			) : (
				<>
					<h2>{`${parsedRecipe.opskriftNavn
						?.slice(0, 1)
						.toUpperCase()}${parsedRecipe.opskriftNavn
						?.slice(1)
						.toLowerCase()}`}</h2>

					<h3>{parsedRecipe.beskrivelse}</h3>
					<h4 style={{ marginTop: '25px' }}>
						Næringsindhold, pr. 100 g
					</h4>
					<ul style={{ listStyle: 'none', lineHeight: '25px' }}>
						<li>
							Kalorieindhold:{' '}
							{parsedRecipe.næringsindhold?.kalorier}
						</li>
						<li>
							Kulhydratindhold:{' '}
							{parsedRecipe.næringsindhold?.kulhydrater}
						</li>
						<li>
							Proteinindhold:{' '}
							{parsedRecipe.næringsindhold?.protein}
						</li>
						<li>
							Fedtindhold: {parsedRecipe.næringsindhold?.fedt}
						</li>
					</ul>

					<h4 style={{ marginTop: '35px' }}>
						<em>
							Ingredienser til {parsedRecipe.antalPersoner}{' '}
							personer
						</em>
					</h4>
					<p>
						<ul style={{ listStyle: 'none', lineHeight: '25px' }}>
							{parsedRecipe.ingredienser?.length > 0 &&
								parsedRecipe.ingredienser.map(ingredient => (
									<li>
										{/* {`${ingredient[1]} ${
										ingredient[2]
									} ${ingredient[0].toLowerCase()}`} */}
										{ingredient.mængde} {ingredient.enhed}{' '}
										{ingredient.navn.toLowerCase()}
									</li>
								))}
						</ul>
					</p>
					<h4>
						<em>Fremgangsmåde</em>
					</h4>
					<p>
						<ol style={{ listStyle: 'none', lineHeight: '40px' }}>
							{/* {parsedRecipe.steps?.length > 0 &&
								parsedRecipe.steps.map(step => (
									<li>{step.text}</li>
								))} */}
							{parsedRecipe.instruktioner?.length > 0 &&
								parsedRecipe.instruktioner.map(step => (
									<li>
										{step.nummer}. {step.text}
									</li>
								))}
						</ol>
					</p>
					<h4>{parsedRecipe.blogTitle}</h4>
					<p>{parsedRecipe.blogPost}</p>
				</>
			)}
		</Container>
	);
};

export default Recipe;
