import React from 'react';
import { Box } from '@mui/material';
import Benefits from './LandingPage/Benefits/Benefits';
import CTA from './LandingPage/CTA/CTA';
import HeroSection from './LandingPage/Hero/Hero';

const Home = () => {
	return (
		<main>
			<section>
				<HeroSection />
			</section>
			<Box sx={{ margin: '15px' }}>
				<section>
					<Benefits />
				</section>
				<section>
					<CTA />
				</section>
			</Box>
		</main>
	);
};

export default Home;
