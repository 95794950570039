import React, { useState, useEffect } from 'react';
import api from '../services/api';

const Settings = () => {
  const [settings, setSettings] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const result = await api.get('/settings');
      setSettings(result.data);
    };
    fetchData();
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSettings((prevSettings) => ({ ...prevSettings, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    await api.put('/settings', settings);
  };

  return (
    <main>
      <h1>Settings</h1>
      <form onSubmit={handleSubmit}>
        <label htmlFor="planningDays">Number of planning days</label>
        <input
          type="number"
          id="planningDays"
          name="planningDays"
          value={settings.planningDays}
          onChange={handleInputChange}
        />
        <label htmlFor="mealsPerDay">Number of meals per day</label>
        <input
          type="number"
          id="mealsPerDay"
          name="mealsPerDay"
          value={settings.mealsPerDay}
          onChange={handleInputChange}
        />
        <button type="submit">Save</button>
      </form>
    </main>
  );
};

export default Settings;
