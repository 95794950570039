/** @format */

import React from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';

function Categories({ selectedOption, setSelectedOption }) {
	const [state, setState] = React.useState({
		morgenmad: false,
		frokost: false,
		aftensmad: false,
		snacks: false,
	});

	const options = [
		{ value: 'morgenmad', label: 'Morgenmad' },
		{ value: 'frokost', label: 'Frokost' },
		{ value: 'aftensmad', label: 'Aftensmad' },
		{ value: 'dessert', label: 'Dessert' },
		{ value: 'snack', label: 'Snack' },
	];

	const handleChange = event => {
		const name = event.target.name;
		setState({ ...state, [name]: event.target.checked });
		setSelectedOption(name);

		// Uncheck all other options
		Object.keys(state).forEach(option => {
			if (option !== name) {
				setState({ ...state, [option]: false });
			}
		});
	};

	return (
		<div>
			{options.map(option => {
				return (
					<FormControlLabel
						control={
							<Checkbox
								checked={option.value === selectedOption}
								onChange={handleChange}
								name={option.value}
							/>
						}
						label={option.label}
						key={option.value}
					/>
				);
			})}
		</div>
	);
}
export default Categories;
