import * as React from 'react';
import PropTypes from 'prop-types';
import { useAutocomplete } from '@mui/base/AutocompleteUnstyled';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { autocompleteClasses } from '@mui/material/Autocomplete';

const Root = styled('div')(
	({ theme }) => `
  color: ${
		theme.palette.mode === 'dark'
			? 'rgba(255,255,255,0.65)'
			: 'rgba(0,0,0,.85)'
  };
  font-size: 14px;
`
);

const Label = styled('label')`
	padding: 0 0 4px;
	line-height: 1.5;
	display: block;
`;

const InputWrapper = styled('div')(
	({ theme }) => `
  width: 300px;
  border: 1px solid ${theme.palette.mode === 'dark' ? '#434343' : '#d9d9d9'};
  background-color: ${theme.palette.mode === 'dark' ? '#141414' : '#fff'};
  border-radius: 4px;
  padding: 1px;
  display: flex;
  flex-wrap: wrap;

  &:hover {
    border-color: ${theme.palette.mode === 'dark' ? '#177ddc' : '#40a9ff'};
  }

  &.focused {
    border-color: ${theme.palette.mode === 'dark' ? '#177ddc' : '#40a9ff'};
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }

  & input {
    background-color: ${theme.palette.mode === 'dark' ? '#141414' : '#fff'};
    color: ${
		theme.palette.mode === 'dark'
			? 'rgba(255,255,255,0.65)'
			: 'rgba(0,0,0,.85)'
	};
    height: 30px;
    box-sizing: border-box;
    padding: 4px 6px;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
  }
`
);

function Tag(props) {
	const { label, onDelete, ...other } = props;
	return (
		<div {...other}>
			<span>{label}</span>
			<CloseIcon onClick={onDelete} />
		</div>
	);
}

Tag.propTypes = {
	label: PropTypes.string.isRequired,
	onDelete: PropTypes.func.isRequired,
};

const StyledTag = styled(Tag)(
	({ theme }) => `
  display: flex;
  align-items: center;
  height: 24px;
  margin: 2px;
  line-height: 22px;
  background-color: ${
		theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.08)' : '#fafafa'
  };
  border: 1px solid ${theme.palette.mode === 'dark' ? '#303030' : '#e8e8e8'};
  border-radius: 2px;
  box-sizing: content-box;
  padding: 0 4px 0 10px;
  outline: 0;
  overflow: hidden;

  &:focus {
    border-color: ${theme.palette.mode === 'dark' ? '#177ddc' : '#40a9ff'};
    background-color: ${theme.palette.mode === 'dark' ? '#003b57' : '#e6f7ff'};
  }

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & svg {
    font-size: 12px;
    cursor: pointer;
    padding: 4px;
  }
`
);

const Listbox = styled('ul')(
	({ theme }) => `
  width: 300px;
  margin: 2px 0 0;
  padding: 0;
  position: absolute;
  list-style: none;
  background-color: ${theme.palette.mode === 'dark' ? '#141414' : '#fff'};
  overflow: auto;
  max-height: 250px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1;

  & li {
    padding: 5px 12px;
    display: flex;

    & span {
      flex-grow: 1;
    }

    & svg {
      color: transparent;
    }
  }

  & li[aria-selected='true'] {
    background-color: ${theme.palette.mode === 'dark' ? '#2b2b2b' : '#fafafa'};
    font-weight: 600;

    & svg {
      color: #1890ff;
    }
  }

  & li.${autocompleteClasses.focused} {
    background-color: ${theme.palette.mode === 'dark' ? '#003b57' : '#e6f7ff'};
    cursor: pointer;

    & svg {
      color: currentColor;
    }
  }
`
);

export default function CustomizedHook({ setSearchTerm }) {
	const {
		getRootProps,
		getInputLabelProps,
		getInputProps,
		getTagProps,
		getListboxProps,
		getOptionProps,
		groupedOptions,
		value,
		focused,
		setAnchorEl,
	} = useAutocomplete({
		id: 'customized-hook-demo',
		defaultValue: [ingredients[1]],
		multiple: true,
		options: ingredients,
		getOptionLabel: option => option.name,
	});
	React.useEffect(() => {
		if (value.length > 0) {
			setSearchTerm(value);
		}
	}, [value, setSearchTerm]);

	return (
		<Root>
			<div {...getRootProps()}>
				<Label {...getInputLabelProps()}>Indtast ingredienser</Label>
				<InputWrapper
					ref={setAnchorEl}
					className={focused ? 'focused' : ''}>
					{value.map((option, index) => (
						<StyledTag
							label={option.name}
							{...getTagProps({ index })}
						/>
					))}

					<input {...getInputProps()} />
				</InputWrapper>
			</div>
			{groupedOptions.length > 0 ? (
				<Listbox {...getListboxProps()}>
					{groupedOptions.map((option, index) => (
						<li {...getOptionProps({ option, index })}>
							<span>{option.name}</span>
							<CheckIcon fontSize='small' />
						</li>
					))}
				</Listbox>
			) : null}
		</Root>
	);
}

const ingredients = [
	{ name: 'ananas', type: 'kolonial' },
	{ name: 'appelsin', type: 'grøntsager' },
	{ name: 'appelsinjuice', type: 'kolonial' },
	{ name: 'aubergine', type: 'grøntsager' },
	{ name: 'balsamicoeddike', type: 'kolonial' },
	{ name: 'blåbær', type: 'frost' },
	{ name: 'blomkål', type: 'grøntsager' },
	{ name: 'broccoli', type: 'grøntsager' },
	{ name: 'broccolirødder', type: 'grøntsager' },
	{ name: 'cashewnødder', type: 'kolonial' },
	{ name: 'creme fraiche', type: 'mejeri' },
	{ name: 'feta', type: 'mejeri' },
	{ name: 'flødeis', type: 'frost' },
	{ name: 'flødeost', type: 'mejeri' },
	{ name: 'flåede tomater', type: 'kolonial' },
	{ name: 'friske ingefær', type: 'grøntsager' },
	{ name: 'friske krydderurter', type: 'grøntsager' },
	{ name: 'friske tomater', type: 'grøntsager' },
	{ name: 'ferskner', type: 'frost' },
	{ name: 'gorgonzola', type: 'mejeri' },
	{ name: 'grapefrugt', type: 'grøntsager' },
	{ name: 'græsk yoghurt', type: 'mejeri' },
	{ name: 'grønne bønner', type: 'grøntsager' },
	{ name: 'grønne ærter', type: 'grøntsager' },
	{ name: 'gulerødder', type: 'grøntsager' },
	{ name: 'hakket oksekød', type: 'kød' },
	{ name: 'hindbær', type: 'frost' },
	{ name: 'hvedemel', type: 'kolonial' },
	{ name: 'hvide rejer', type: 'fisk' },
	{ name: 'hvidløg', type: 'grøntsager' },
	{ name: 'isvafler', type: 'frost' },
	{ name: 'jordbær', type: 'frost' },
	{ name: 'jordnødder', type: 'kolonial' },
	{ name: 'kalkun', type: 'kød' },
	{ name: 'kalvekød', type: 'kød' },
	{ name: 'kartofler', type: 'grøntsager' },
	{ name: 'kikærter', type: 'kolonial' },
	{ name: 'kiwi', type: 'grøntsager' },
	{ name: 'kyllingebryst', type: 'kød' },
	{ name: 'kyllingelever', type: 'kød' },
	{ name: 'lammekølle', type: 'kød' },
	{ name: 'laks', type: 'fisk' },
	{ name: 'løg', type: 'grøntsager' },
	{ name: 'majs', type: 'kolonial' },
	{ name: 'mozzarella', type: 'mejeri' },
	{ name: 'muslinger', type: 'fisk' },
	{ name: 'nødder', type: 'kolonial' },
	{ name: 'ost', type: 'mejeri' },
	{ name: 'pastinak', type: 'grøntsager' },
	{ name: 'pærer', type: 'frost' },
	{ name: 'porrer', type: 'grøntsager' },
	{ name: 'rejer', type: 'fisk' },
	{ name: 'rødbeder', type: 'grøntsager' },
	{ name: 'rødspætte', type: 'fisk' },
	{ name: 'rødvin', type: 'kolonial' },
	{ name: 'sesamfrø', type: 'kolonial' },
	{ name: 'sherry', type: 'kolonial' },
	{ name: 'sherryeddike', type: 'kolonial' },
	{ name: 'skinke', type: 'kød' },
	{ name: 'småkager', type: 'mejeri' },
	{ name: 'soja sauce', type: 'kolonial' },
	{ name: 'sorbet', type: 'frost' },
	{ name: 'sødmælk', type: 'mejeri' },
	{ name: 'søde kartofler', type: 'grøntsager' },
	{ name: 'spidskål', type: 'grøntsager' },
	{ name: 'svinemørbrad', type: 'kød' },
	{ name: 'tahin', type: 'kolonial' },
	{ name: 'torsk', type: 'fisk' },
	{ name: 'torskerogn', type: 'fisk' },
	{ name: 'tun', type: 'fisk' },
	{ name: 'vaniljeis', type: 'frost' },
	{ name: 'yoghurt', type: 'mejeri' },
	{ name: 'æble', type: 'grøntsager' },
	{ name: 'æg', type: 'mejeri' },
	{ name: 'østers', type: 'fisk' },
];
