import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import api from '../services/api';

const ShoppingList = () => {
	const [shoppingList, setShoppingList] = useState([]);

	useEffect(() => {
		const fetchData = async () => {
			const result = await api.get('/shopping-list');
			setShoppingList(result.data);
		};
		fetchData();
	}, []);

	return (
		<main>
			<h1>Shopping List</h1>
			<ul>
				{shoppingList.map(item => (
					<li key={item.id}>{item.name}</li>
				))}
			</ul>
			<Link to='/generate-shopping-list'>Generate shopping list</Link>
		</main>
	);
};

export default ShoppingList;
